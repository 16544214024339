<template>
  <CRow>
    <CCol col="12" xl="9">
      <CCard>
        <CCardHeader class="d-flex justify-content-between ">
          <h3>Settings</h3>
        </CCardHeader>
        <CCardBody>
                        <template>
                            <form @submit.prevent="handleSubmit">
                                <div class="pl-lg-4">
                                    <div class="row">
                                     <div :key="index" class="col-lg-12" v-for="(field, index) in fieldData">
                                         <h5 class="text-muted float-right opacity-4">{{field.slug}}</h5>
                                           <a-form-item :label="field.title" v-if="field.type === '0'">
                                               <a-input alternative=""
                                                        input-classes="form-control-alternative"
                                                        v-model="fieldData[field.slug].value"
                                            />
                                            </a-form-item>
                                         <div class="mb-4" v-if="field.type === '12' || field.type === '13' " >
                                             <label class="form-control-label">{{field.title}}</label>
                                             <a-select v-if="fieldData[field.slug].value"
                                                       mode="multiple" style="width: 100%" :defaultValue = "JSON.parse(fieldData[field.slug].value)"
                                                       @change="(e) => onChildChange(e,field.slug)" placeholder="Please select">
                                                 <a-select-option v-for="op in options[field.id]" :key="op.value">{{op.label}}</a-select-option>
                                             </a-select>

                                             <a-select v-else
                                                       mode="multiple"
                                                       style="width: 100%"
                                                       @change="(e) => onChildChange(e,field.slug)"
                                                       placeholder="Please select"
                                             >
                                                 <a-select-option v-for="op in options[field.id]" :key="op.value">{{op.label}}</a-select-option
                                                 >
                                             </a-select>

                                         </div>
                                         <div class="mb-4 multi-items" v-if="field.type === '3' && fieldData[field.slug].value">
                                             <label class="form-control-label">{{field.title}}</label>
                                             <image-upload :data="JSON.parse(fieldData[field.slug].value)" :title="field.title" :dat="field.slug" @changed="(e) => onChildChange(e,field.slug)"></image-upload>
                                         </div>
                                         <div class="mb-4 multi-items" v-if="field.type === '9'" >
                                             <label class="form-control-label">{{field.title}}</label>


                                             <a-form-item
                                                     v-for="(k, index) in form.getFieldValue('keys')"
                                                     :key="k"
                                                     v-bind="formItemLayout"
                                                                                                         :required="false"

                                             >
                                                 <a-input placeholder="Názov štýlu" class="style-name" @change="(e) => varItems(e,field.slug)" v-model="styles.name[index]"/>
                                                 <a-input-number class="style-prop" placeholder="šírka" @change="(e) => varItems(e,field.slug)" v-model="styles.width[index]"/>
                                                 <a-input-number class="style-prop" placeholder="výška" @change="(e) => varItems(e,field.slug)" v-model="styles.height[index]" />
                                                 <a-icon
                                                         v-if="form.getFieldValue('keys').length > 1"
                                                         class="dynamic-delete-button"
                                                         type="minus-circle-o"
                                                         :disabled="form.getFieldValue('keys').length === 1"
                                                         @click="() => remove(k)"
                                                 />
                                             </a-form-item>
                                             <a-form-item >
                                                 <a-button type="dashed" style="width: 20%" @click="add">
                                                     <a-icon type="plus" /> Add field
                                                 </a-button>
                                             </a-form-item>


                                         </div>
                                         <a-checkbox v-if="field.type === '14'" class="mb-3" v-model="fieldData[field.slug].value">{{field.title}}</a-checkbox>

                                         <div class="mb-4" v-if="field.type === '4'" >
                                             <label class="form-control-label">{{field.title}}</label>
                                             <textarea class="form-control" id="exampleFormControlTextarea1" rows="3"
                                                       v-model="fieldData[field.slug].value"
                                                       :placeholder="field.title"></textarea>
                                         </div>
                                     </div>
                                        </div>
                                    </div>
                                <div class="text-right">
   <button class="btn btn-primary">Uložiť</button>
                                </div>

                            </form>
                        </template>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>
<script>
    import {getURL} from "../api";
    import {dataService} from "../_services";
    import Image from "./content/Image";

    import FormItem from 'ant-design-vue'
    import Vue from "vue"
    Vue.use(FormItem);
    let id = 0;
    export default {
        name: 'variables',
        components: {'image-upload' : Image},
        data() {
            return {
                url: getURL(),
                colorsto: JSON.parse(localStorage.colors),
                fieldData: {},
                styles: {'name': [],'width': [],'height': []},
                formItemLayout: {
                    labelCol: {
                        xs: {span: 24},
                        sm: {span: 4},
                    },
                    wrapperCol: {
                        xs: {span: 24},
                        sm: {span: 20},
                    },
                },
                formItemLayoutWithOutLabel: {
                    wrapperCol: {
                        xs: {span: 24, offset: 0},
                        sm: {span: 20, offset: 4},
                    },
                },
            }
        },
        beforeCreate() {
            this.form = this.$form.createForm(this, {name: 'dynamic_form_item'});
            this.form.getFieldDecorator('keys', {initialValue: [this.styles], preserve: true});
        },
        methods: {
            remove(k) {
                const { form } = this;
                // can use data-binding to get
                const keys = form.getFieldValue('keys');
                // We need at least one passenger
                if (keys.length === 1) {
                    return;
                }

                // can use data-binding to set
                form.setFieldsValue({
                    keys: keys.filter(key => key !== k),
                });
            },
            varItems(e,slug){
                this.fieldData[slug].value = JSON.stringify(this.styles);
            },
            add() {
                const { form } = this;
                // can use data-binding to get
                const keys = form.getFieldValue('keys');
                const nextKeys = keys.concat(id++);
                // can use data-binding to set
                // important! notify form to detect changes
                form.setFieldsValue({
                    keys: nextKeys,
                });
            },
            onChildChange(value,slug) {
                this.fieldData[slug].value = JSON.stringify(value);
            },

            handleSubmit() {
            dataService.axiosPost(this.$options.name, this.fieldData).then(results => {
                    if (results.data.success) {
                        this.$message.success('Nastavenia boli uložené');
                    } else {
                        this.$message.error('Pri ukladaní nastala chyba');
                    }
                });
            },
        },
        mounted() {
            dataService.axiosFetch(this.$options.name,"web").then(results => {
                this.fieldData = results;
               if(this.fieldData)
               this.options = results.options;

                this.styles = JSON.parse(this.fieldData['image_styles'].value);
            });
        }
    };
</script>
<style scoped>

    .input-group .input-group-prepend .input-group-text {
        border: 0;
    }

    .dynamic-delete-button {
        cursor: pointer;
        position: relative;
        top: 4px;
        font-size: 24px;
        color: #999;
        transition: all 0.3s;
    }
.multi-items .ant-form-item
{margin-bottom:0}
    .add-button i
    {
        position: relative;
        top: -3px;}
    .dynamic-delete-button:hover {
        color: #777;
    }

    .dynamic-delete-button[disabled] {
        cursor: not-allowed;
        opacity: 0.5;
    }

    .ant-select-selection--single {
        width: 100%;
        height: calc(1.5em + 1.25rem + 2px);
        padding: 0.625rem 0.75rem;
    }
    .dynamic-delete-button {
        cursor: pointer;
        position: relative;
        top: 4px;
        font-size: 24px;
        color: #999;
        transition: all 0.3s;
    }
    .dynamic-delete-button:hover {
        color: #777;
    }
    .dynamic-delete-button
    { position: relative;
      top: -6px;}

    .style-prop
    {float:left;width:20%;margin-right:1%;}
    .style-name
    {float:left;width:50%;
        margin-right:1%;}
    .dynamic-delete-button[disabled] {
        cursor: not-allowed;
        opacity: 0.5;
    }

</style>
